var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'c-burger-menu-arrow',
		'relative w-40 h-26',
		'duration-500 ease-smooth-out',
		'transform',

		{
			'translate-x-0': _vm.active,
			'-translate-x-11 group-hover:translate-x-0': !_vm.active,
		},
	]},[_c('SvgCaretMenu',{class:[
			'h-26 transform',
			'duration-500 ease-smooth-out',

			{
				'translate-x-0 text-primary-dark': _vm.active,
				'-translate-x-16 group-hover:translate-x-0': !_vm.active,
			},
		]}),_vm._v(" "),_c('SvgCaretMenuLine',{class:[
			'h-26 transform',
			'absolute top-0 left-0',
			'duration-500 ease-smooth-out',

			{
				'scale-x-100 text-primary-dark': _vm.active,
				'scale-x-0 group-hover:scale-x-100': !_vm.active,
			},
		]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }