<!--
	Note:
	I have setup the search field to be handled
	very much from the outside, to allow for the
	fancy transition to the search site.

	The sizing and transitioning should be handled
	from the outside.

	To detect a search request from the search field,
	use the global event bus:

	import globalEventBus from '~/assets/js/global-event-bus';
	globalEventBus.$on('globalSearch:request', searchText => {
		...
	});

	To update the search string from elsewhere, use
	the global event bus:

	import globalEventBus from '~/assets/js/global-event-bus';
	globalEventBus.$emit('globalSearch:textUpdate', "Some search string");
-->
<template>
	<div v-if="searchPage" class="c-site-header-search">
		<div
			class="
				relative
				inline-flex
				w-full
				border-current
				rounded-full
				isolate
			"
			:class="{
				'rounded-b-none rounded-t-24 >=768:rounded-t-30 border-b-0':
					isAutoCompleteOpen,
			}"
			style="height: inherit; border-width: 2px; border-width: 1.5px"
		>
			<!-- The search input -->
			<TransitionExt name="fade-in-out" duration="500">
				<input
					v-if="showInput"
					ref="input"
					v-model="searchText"
					type="search"
					role="searchbox"
					:aria-autocomplete="useAutosuggest ? 'list' : null"
					:aria-controls="
						useAutosuggest
							? `c-site-header-search-${_uid}__auto-complete`
							: null
					"
					:class="[
						'absolute left-0 top-0 w-full h-full rounded-full',
						'pl-xl pr-48 pb-4 appearance-none align-middle',
						'font-darker-grotesque font-semibold text-input leading-1 placeholder-text-placeholder',
						'<360:text-input-sm',
					]"
					placeholder="Hvad kan vi hjælpe dig med?"
					@keydown.enter="triggerSearch"
				/>
			</TransitionExt>

			<!-- The search button -->
			<Component
				:is="buttonBindings.is"
				v-bind="buttonBindings"
				class="c-site-header-search__button"
				:class="[
					'relative rounded-full overflow-hidden',
					'ml-auto max-w-full h-full',
					whiteBackground ? 'bg-white' : 'bg-transparent',
					'flex justify-center items-center',
					'transition-all duration-500 isolate',
					'h-full',
				]"
				style="aspect-ratio: 1"
				@click="triggerSearch"
			>
				<SvgIconSearch class="w-16 h-16 >=1024:w-15 >=1024:h-15" />
			</Component>
		</div>

		<!-- AutoCompleteList -->
		<template v-if="useAutosuggest">
			<AutoCompleteListCustom
				v-show="showInput"
				:id="`c-site-header-search-${_uid}__auto-complete`"
				class="relative -mt-2 w-full z-10"
				@search-suggestion="triggerSearchWithSuggestion"
				@state-change="isAutoCompleteOpen = $event"
			/>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import AutoCompleteListCustom from '~/components/shared/AutoCompleteListCustom';
import SvgIconSearch from '~/assets/svgs/icon-search.svg?inline';
import globalEventBus from '~/assets/js/global-event-bus';

export default {
	name: 'SiteHeaderSearch',
	components: { AutoCompleteListCustom, SvgIconSearch },

	props: {
		buttonMode: {
			type: String,
			default: 'link',
			validator: (value) => ['link', 'button'].includes(value),
		},
		showInput: {
			type: Boolean,
			default: false,
		},
		whiteBackground: {
			type: Boolean,
			default: false,
		},
		useAutosuggest: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const { searchPage } = this.$store.state.site || {};
		return {
			searchPage: searchPage,
			searchText: this.$route.query?.text ?? '',
			isAutoCompleteOpen: false,
		};
	},

	computed: {
		searchUrl() {
			return [this.searchPage?.url, this.searchText]
				.filter(Boolean)
				.join('?text=');
		},

		buttonBindings() {
			return this.buttonMode === 'link'
				? {
						is: 'NuxtLinkExt',
						to: this.searchUrl,
						target: '_self',
						'aria-label': 'Åben søgning',
				  }
				: {
						is: 'button',
						'aria-label': 'Søg',
				  };
		},

		...mapState({
			template: (state) => state.template,
		}),
	},

	watch: {
		searchText(newValue) {
			globalEventBus.$emit('globalSearch:textUpdate', newValue);
		},

		template(value) {
			if (value === 'SearchPage') {
				this.$nextTick(() => {
					this.$refs.input?.focus?.();
				});
			}
		},
	},

	mounted() {
		globalEventBus.$on('globalSearch:textUpdate', (searchText) => {
			if (this.searchText !== searchText) {
				this.searchText = searchText;
			}
		});
	},

	methods: {
		triggerSearch() {
			if (this.buttonMode === 'button') {
				globalEventBus.$emit('globalSearch:request', this.searchText);
			}
		},
		triggerSearchWithSuggestion(suggestion) {
			this.searchText = suggestion;
			this.triggerSearch();
		},
	},
};
</script>

<style lang="postcss">
.c-site-header-search {
	@apply relative w-48 >=768:w-40 h-48 >=768:h-40;
}

.c-site-header-search__button:before {
	@apply absolute -left-full -top-1 -bottom-1 w-full;
	@apply rounded-r-full bg-black bg-opacity-10;
	@apply duration-500 ease-smooth-out transform opacity-50;
	content: '';
	z-index: -1;
}
.c-site-header-search__button:hover:before {
	@apply translate-x-full opacity-100;
}
</style>
