var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"Component",staticClass:"c-scoped-color-theme",class:{
		[`c-scoped-color-theme--${_vm.mappedTheme}`]: _vm.mappedTheme,
	},style:({
		...(_vm.mappedTheme
			? {
					'--theme-colors-primary': `var(--color-theme-${_vm.solution}-${
						_vm.mappedTheme === 'prism'
							? _vm.currentPrismTheme
							: _vm.mappedTheme
					}-primary)`,
					'--theme-colors-primary-dark': `var(--color-theme-${_vm.solution}-${
						_vm.mappedTheme === 'prism'
							? _vm.currentPrismTheme
							: _vm.mappedTheme
					}-primary-dark)`,
					'--theme-colors-secondary': `var(--color-theme-${_vm.solution}-${
						_vm.mappedTheme === 'prism'
							? _vm.currentPrismTheme
							: _vm.mappedTheme
					}-secondary)`,
			  }
			: {}),
		'--scoped-color-theme-duration': `${_vm.partialPrismDuration}ms`,
	})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }