var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-site-footer"},[_c('div',{staticClass:"relative h-80 >=1024:h-180 overflow-hidden"},[_c('LensFlare',{class:[
				'absolute top-1/2 left-1/2 w-visual-screen max-w-layout-max',
				'transform -translate-x-1/2 -translate-y-1/2',
				'hidden >=1024:block',
			],attrs:{"id":"c-site-footer__flare-desktop","angle":8,"min-blur-amount":5,"max-blur-amount":30,"flares":[
				{
					size: 625,
					offset: 0,
					targetOffset: -150,
					theme: 'primary',
					opacity: 1,
				},
				{
					size: 255,
					targetSize: 30,
					offset: -300,
					targetOffset: -740,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 235,
					targetSize: 180,
					offset: 230,
					targetOffset: 570,
					theme: 'secondary',
					opacity: 0.5,
				},
				{
					size: 135,
					targetSize: 0,
					offset: 230,
					targetOffset: 1230,
					theme: 'secondary',
					opacity: 0.5,
				},
			]}}),_vm._v(" "),_c('LensFlare',{class:[
				'absolute top-1/2 left-1/2 w-visual-screen ',
				'transform -translate-x-1/2 -translate-y-1/2',
				'>=1024:hidden',
			],attrs:{"id":"c-site-footer__flare-mobile","angle":15,"min-blur-amount":15,"max-blur-amount":25,"flares":[
				{
					size: 625,
					offset: -50,
					targetOffset: 50,
					theme: 'primary',
					opacity: 1,
				},
				{
					size: 375,
					targetSize: 150,
					offset: -300,
					targetOffset: -450,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 285,
					targetSize: 150,
					offset: 250,
					targetOffset: 650,
					theme: 'secondary',
					opacity: 0.5,
				},
			]}})],1),_vm._v(" "),_c('footer',{class:[
			'max-w-layout-max px-layout-margin mx-auto',
			'flex flex-col items-start >=1024:grid >=1024:grid-cols-12',
			'>=1024:gap-x-layout-gutter >=1024:gap-y-lg',
			'pb-6xl pt-4xl',
		],attrs:{"id":"footer"}},[_c('SvgLogo',{staticClass:"row-start-1 col-start-1 col-end-7 h-57 >=1024:h-72"}),_vm._v(" "),_c('h2',{staticClass:"sr-only"},[_vm._v("Odsherred Kommune")]),_vm._v(" "),(_vm.address)?_c('div',{class:[
				'row-start-2 row-end-4 col-start-1 col-end-7',
				'mt-2xl >=1024:mt-0',
			]},[_c('div',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.address.street)),_c('br'),_vm._v("\n\t\t\t\t"+_vm._s(_vm.address.postalCode)+" "+_vm._s(_vm.address.city)+"\n\t\t\t")]),_vm._v(" "),(_vm.address.phone || _vm.address.email)?_c('div',{staticClass:"mt-lg"},[(_vm.address.phone)?_c('a',{staticClass:"block",attrs:{"href":`tel:${_vm.address.phone}`}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.address.phone)+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.address.email)?_c('a',{staticClass:"block",attrs:{"href":`mailto:${_vm.address.email}`}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.address.email)+"\n\t\t\t\t")]):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.address && _vm.address.cvr)?_c('div',{staticClass:"row-start-4 col-start-1 col-end-7 mt-lg"},[_vm._v("\n\t\t\tCVR: "+_vm._s(_vm.address.cvr)+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.contact)?[(_vm.contact.title && _vm.contact.title.length)?_c('div',{class:[
					'row-start-1 col-start-7 col-end-10',
					'mt-3xl mb-lg >=1024:my-0',
				]},[_c('BaseH3',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.contact.title)}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row-start-2 col-start-7 col-end-10"},[(_vm.contact.openingHours)?_c('ul',_vm._l((_vm.computedOpeningHours),function(item,index){return _c('li',{key:`opening-hour-${index}`,staticClass:"openinghours flex items-end gap-x-10",domProps:{"innerHTML":_vm._s(_vm.formatOpeningHour(item))}})}),0):_vm._e()]),_vm._v(" "),(_vm.contact.book && _vm.contact.book.url)?_c('div',{class:[
					'row-start-3 col-start-7 col-end-10',
					'mt-lg >=1024:mt-0',
				]},[_c('NuxtLinkExt',{class:[
						'c-site-footer__link pr-8',
						'flex items-center space-x-16',
						'text-button font-medium font-darker-grotesque',
						'group',
					],attrs:{"to":_vm.contact.book.url,"target":_vm.contact.book.target}},[_c('SvgArrow',{class:[
							'w-16 text-text transform',
							{
								'-rotate-45':
									_vm.contact.book.target === '_blank' ||
									_vm.isExternal(_vm.contact.book.url),
							},
						]}),_vm._v(" "),_c('div',{class:[
							'mb-4',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
						],domProps:{"textContent":_vm._s(
							_vm.contact.book.name !== _vm.contact.book.url
								? _vm.contact.book.name
								: 'Bestil tid'
						)}})],1)],1):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.seeAlso)?[(_vm.seeAlso.title && _vm.seeAlso.title.length)?_c('div',{staticClass:"row-start-1 col-start-10 col-end-13 mt-3xl >=1024:mt-0"},[_c('BaseH3',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.seeAlso.title)}})],1):_vm._e(),_vm._v(" "),_c('div',{class:[
					'row-start-2 col-start-10 col-end-13',
					'mt-lg >=1024:mt-0',
				]},[_c('div',{staticClass:"space-y-8"},_vm._l((_vm.seeAlso.links),function(link,index){return _c('NuxtLinkExt',{key:`see-also-link-${index}`,class:[
							'c-site-footer__link pr-8',
							'flex items-start space-x-16',
							'text-button font-medium font-darker-grotesque',
							'group',
						],attrs:{"to":link.url,"target":link.target}},[_c('SvgArrow',{class:[
								'flex-shrink-0',
								'mt-4 w-16 text-text',

								{
									'transform -rotate-45':
										link.url === '_blank' ||
										_vm.isExternal(link.url),
								},
							]}),_vm._v(" "),_c('div',{class:[
								'mb-4',
								'duration-500 ease-smooth-out',
								'transform group-hover:translate-x-8',
							],domProps:{"textContent":_vm._s(link.name)}})],1)}),1)]),_vm._v(" "),(_vm.seeAlso.readOutLoud || !_vm.isSystemReducedMotion)?_c('div',{class:[
					'row-start-3 col-start-10 col-end-13',
					'space-y-8',
					'mt-8 >=1024:mt-0',
				]},[(_vm.seeAlso.readOutLoud)?_c('NuxtLinkExt',{class:[
						'flex items-center space-x-12',
						'text-button font-medium font-darker-grotesque',
						'group pr-8',
					],attrs:{"to":_vm.seeAlso.readOutLoud.url,"target":_vm.seeAlso.readOutLoud.target}},[_c('SvgSpeaker',{staticClass:"flex-shrink-0 w-18"}),_vm._v(" "),_c('div',{class:[
							'mb-4',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
						],domProps:{"textContent":_vm._s('Læs højt')}})],1):_vm._e(),_vm._v(" "),(!_vm.isSystemReducedMotion)?_c('button',{class:[
						'flex items-center space-x-12',
						'text-button font-medium font-darker-grotesque',
						'group pr-8',
					],on:{"click":_vm.toggleAnimation}},[(_vm.isReducedMotion)?_c('SvgIconPlay',{staticClass:"flex-shrink-0 w-18"}):_c('SvgIconPause',{staticClass:"flex-shrink-0 w-18"}),_vm._v(" "),_c('div',{class:[
							'mb-4',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
						],domProps:{"textContent":_vm._s(
							_vm.isReducedMotion
								? 'Slå animeret pynt til'
								: 'Slå animeret pynt fra'
						)}})],1):_vm._e()],1):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.someItems)?_c('div',{staticClass:"row-start-4 col-start-10 col-end-13"},[_c('div',{staticClass:"flex space-x-12 mt-4xl >=1024:mt-lg"},_vm._l((_vm.someItems),function({ serviceName, url, icon },index){return _c('a',{key:`some-${index}`,attrs:{"href":url,"aria-label":`gå til ${serviceName}`}},[_c(icon,{tag:"Component",staticClass:"text-text w-32"})],1)}),0)]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }