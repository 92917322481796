<template>
	<div
		:class="[
			'c-burger-menu-arrow',
			'relative w-40 h-26',
			'duration-500 ease-smooth-out',
			'transform',

			{
				'translate-x-0': active,
				'-translate-x-11 group-hover:translate-x-0': !active,
			},
		]"
	>
		<SvgCaretMenu
			:class="[
				'h-26 transform',
				'duration-500 ease-smooth-out',

				{
					'translate-x-0 text-primary-dark': active,
					'-translate-x-16 group-hover:translate-x-0': !active,
				},
			]"
		/>
		<SvgCaretMenuLine
			:class="[
				'h-26 transform',
				'absolute top-0 left-0',
				'duration-500 ease-smooth-out',

				{
					'scale-x-100 text-primary-dark': active,
					'scale-x-0 group-hover:scale-x-100': !active,
				},
			]"
		/>
	</div>
</template>

<script>
import SvgCaretMenu from '~/assets/svgs/icon-caret-menu.svg?inline';
import SvgCaretMenuLine from '~/assets/svgs/icon-caret-menu-line.svg?inline';

export default {
	name: 'BurgerMenuArrow',
	components: {
		SvgCaretMenu,
		SvgCaretMenuLine,
	},

	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
