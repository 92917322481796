<template>
	<AccordionGroup v-if="items.length" class="c-language-picker">
		<!-- The button itself -->
		<AccordionHeader
			:id="`picker-${_uid}`"
			:key="`picker-${_uid}`"
			:aria-controls="`picker-panel-${_uid}`"
			:class="[
				'flex items-baseline gap-x-6',
				'font-darker-grotesque font-semibold',
				'text-button-xs uppercase',
			]"
		>
			<span v-text="currentLanguage"></span>
			<SvgCaret
				class="relative w-7 h-14 top-2 duration-150 ease-smooth-out"
				:class="{
					'transform -rotate-180': isOpen,
				}"
			/>
		</AccordionHeader>

		<!-- Small arrow-indicator -->
		<div
			v-show="isOpen"
			:class="[
				'c-language-picker__arrow',
				'absolute top-full left-1/3',
				'transform -translate-x-1/2',
			]"
		></div>

		<AccordionPanel
			:id="`picker-panel-${_uid}`"
			:key="`picker-panel-${_uid}`"
			ref="panel"
			:class="[
				'c-language-picker__dropdown',
				'absolute left-auto -right-1 z-30 py-11',
				'border-2 border-black bg-white ',
				'rounded-12 min-w-min w-175 space-y-16',
			]"
			tag="ol"
			@change="onPanelChange"
		>
			<li v-for="(item, index) in items" :key="`item-${index}`">
				<a
					:href="item.url"
					:target="item.target"
					:class="[
						'text-picker font-darker-grotesque font-bold uppercase align-baseline opacity-70',
						'flex items-center gap-x-32 pl-14 pr-20',
						'focus:bg-background focus:opacity-100 hover:bg-background hover:opacity-100',
					]"
				>
					<span v-text="item.name"></span>
					<span
						:class="[
							'flex-shrink-0 ml-auto',
							'font-semibold text-button-xs uppercase',
						]"
						v-text="item.title"
					></span>
				</a>
			</li>
		</AccordionPanel>
	</AccordionGroup>
</template>

<script>
import {
	AccordionGroup,
	AccordionHeader,
	AccordionPanel,
} from '~/citi-baseline/components/Accordion';
import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'LanguagePicker',

	components: {
		AccordionGroup,
		AccordionHeader,
		AccordionPanel,
		SvgCaret,
	},

	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		const { url } = this.$store.state.site || {};
		const currentLanguage = url.split('/').filter(Boolean).join(' ');

		return {
			currentLanguage,
			isOpen: false,
		};
	},

	mounted() {
		window.addEventListener('click', this.onOutsideClick);
	},

	beforeDestroy() {
		window.removeEventListener('click', this.onOutsideClick);
	},

	methods: {
		onPanelChange(event) {
			this.isOpen = event.isOpen;
		},

		onOutsideClick(e) {
			if (this.isOpen) {
				const path = [...(e?.composedPath?.() ?? [])];
				if (!path.includes(this.$el) || !path.length) {
					this.closePanel();
				}
			}
		},

		closePanel() {
			if (this.$refs?.panel) {
				this.$refs.panel.close();
			}
		},
	},
};
</script>

<style lang="postcss">
.c-language-picker {
	@apply inline-block relative;
}

.c-language-picker__arrow {
	border: 10px solid transparent;
	border-bottom: 10px solid #000;
}

.c-language-picker__dropdown {
	top: calc(100% + 18px);

	& path {
		stroke-width: 3px;
	}
}
</style>
