var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items.length)?_c('AccordionGroup',{staticClass:"c-language-picker"},[_c('AccordionHeader',{key:`picker-${_vm._uid}`,class:[
			'flex items-baseline gap-x-6',
			'font-darker-grotesque font-semibold',
			'text-button-xs uppercase',
		],attrs:{"id":`picker-${_vm._uid}`,"aria-controls":`picker-panel-${_vm._uid}`}},[_c('span',{domProps:{"textContent":_vm._s(_vm.currentLanguage)}}),_vm._v(" "),_c('SvgCaret',{staticClass:"relative w-7 h-14 top-2 duration-150 ease-smooth-out",class:{
				'transform -rotate-180': _vm.isOpen,
			}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],class:[
			'c-language-picker__arrow',
			'absolute top-full left-1/3',
			'transform -translate-x-1/2',
		]}),_vm._v(" "),_c('AccordionPanel',{key:`picker-panel-${_vm._uid}`,ref:"panel",class:[
			'c-language-picker__dropdown',
			'absolute left-auto -right-1 z-30 py-11',
			'border-2 border-black bg-white ',
			'rounded-12 min-w-min w-175 space-y-16',
		],attrs:{"id":`picker-panel-${_vm._uid}`,"tag":"ol"},on:{"change":_vm.onPanelChange}},_vm._l((_vm.items),function(item,index){return _c('li',{key:`item-${index}`},[_c('a',{class:[
					'text-picker font-darker-grotesque font-bold uppercase align-baseline opacity-70',
					'flex items-center gap-x-32 pl-14 pr-20',
					'focus:bg-background focus:opacity-100 hover:bg-background hover:opacity-100',
				],attrs:{"href":item.url,"target":item.target}},[_c('span',{domProps:{"textContent":_vm._s(item.name)}}),_vm._v(" "),_c('span',{class:[
						'flex-shrink-0 ml-auto',
						'font-semibold text-button-xs uppercase',
					],domProps:{"textContent":_vm._s(item.title)}})])])}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }