<!--
	Last modified: 2022/10/13 16:07:32
	---
	The baseline component is logic only, meaning
	it should never have any markup or styling. It
	is made for extension only.
-->
<script>
export default {
	name: 'AutoCompleteList',

	inheritAttrs: false,

	props: {
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			default: '',
		},

		endpoint: {
			type: String,
			default: '/api/search/autocomplete',
		},
		delay: {
			type: Number,
			default: 500,
		},
	},

	data() {
		return {
			showList: false,
			isLoading: false,

			owner: null,

			searchText: '',
			suggestions: [],
			activeIndex: -1,
			activeDescendant: '',

			timeout: null,
		};
	},
	computed: {
		siteId() {
			return this.$store.state.site?.id;
		},
		cultureId() {
			return this.$store.state.site?.cultureId;
		},
		isOpen() {
			return !!(
				this.showList &&
				(this.suggestions.length || this.isLoading)
			);
		},
	},
	watch: {
		$route() {
			this.updateOwner();
		},

		owner(newOwner, oldOwner) {
			// Detatch events
			if (oldOwner) {
				this.removeInputListeners(oldOwner);
			}
			// Attatch events
			if (newOwner) {
				this.addInputListeners(newOwner);
				this.setActiveDescendantOnOwner();
			}
		},

		activeDescendant() {
			this.setActiveDescendantOnOwner();
		},

		isOpen(val) {
			this.$emit('state-change', val);
		},
	},

	mounted() {
		this.updateOwner();
		document.addEventListener('click', this.handleClickOutside);
		document.addEventListener('focusout', this.handleClickOutside);
	},
	destroyed() {
		document.removeEventListener('click', this.handleClickOutside);
		document.removeEventListener('focusout', this.handleClickOutside);
		this.owner && this.removeInputListeners(this.owner);
	},

	methods: {
		addInputListeners(owner) {
			owner.addEventListener('input', this.onInputChange);
			owner.addEventListener('keydown', this.onInputArrowDown);
			owner.addEventListener('keydown', this.onInputArrowUp);
			owner.addEventListener('keydown', this.onInputEnter);
			owner.addEventListener('focus', this.onInputFocus);
		},
		removeInputListeners(owner) {
			owner.removeEventListener('input', this.onInputChange);
			owner.removeEventListener('keydown', this.onInputArrowDown);
			owner.removeEventListener('keydown', this.onInputArrowUp);
			owner.removeEventListener('keydown', this.onInputEnter);
			owner.removeEventListener('focus', this.onInputFocus);
		},

		onInputChange(e) {
			this.searchText = e.target?.value ?? '';
			if (this.timeout) {
				clearTimeout(this.timeout);
			}

			this.timeout = setTimeout(() => {
				if (this.searchText) {
					this.getSuggestions(this.searchText);
				} else {
					this.suggestions = [];
				}
			}, this.delay);
		},
		onInputArrowDown(e) {
			if (e.key === 'ArrowDown') {
				if (this.showList) {
					if (this.activeIndex < this.suggestions.length - 1) {
						this.activeIndex = this.activeIndex + 1;
						this.setActiveDescendant();
						this.searchText =
							this.suggestions[this.activeIndex].text;
					}
					e.preventDefault();
				}
			}
		},
		onInputArrowUp(e) {
			if (e.key === 'ArrowUp') {
				if (this.showList) {
					if (this.activeIndex > 0) {
						this.activeIndex = this.activeIndex - 1;
						this.setActiveDescendant();
						this.searchText =
							this.suggestions[this.activeIndex].text;
					}
					e.preventDefault();
				}
			}
		},
		onInputEnter(e) {
			if (e.key === 'Enter') {
				if (this.activeIndex !== -1) {
					this.setResult(this.suggestions[this.activeIndex].text);
					e.preventDefault();
				} else {
					this.setResult();
				}
				this.activeIndex = -1;
			}
		},
		onInputFocus() {
			if (this.suggestions.length) {
				this.showList = true;
			}
		},

		handleClickOutside(evt) {
			if (
				!this.$el.contains(evt.target) &&
				!this.owner?.contains(evt.target)
			) {
				this.showList = false;
				this.activeIndex = -1;
			}
		},

		getSuggestions(val) {
			this.isLoading = true;
			this.$axios
				.get(this.endpoint, {
					params: {
						text: val,
						siteId: this.siteId,
						cultureId: this.cultureId,
					},
				})
				.then((res) => {
					if (
						res.data.suggestions.length === 1 &&
						res.data.suggestions[0].text === val
					) {
						this.suggestions = [];
					} else {
						this.suggestions = res.data.suggestions || [];
						this.showList = !!this.suggestions.length;
					}
					this.isLoading = false;
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		getId(index) {
			return `${this.id}__result-item-${index}`;
		},

		setActiveDescendant() {
			this.activeDescendant = this.getId(this.activeIndex);
		},
		setActiveDescendantOnOwner() {
			if (this.owner) {
				this.owner.setAttribute(
					'aria-activedescendant',
					this.activeDescendant || null
				);
			}
		},
		setResult(result) {
			if (result) {
				this.searchText = result;
				this.$emit('search-suggestion', this.searchText);
				this.suggestions = [];
			}
			this.showList = false;
		},

		isSelected(i) {
			return i === this.activeIndex;
		},
		updateOwner() {
			this.owner = document.querySelector(`[aria-controls=${this.id}]`);
		},
	},
};
</script>
