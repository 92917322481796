<template>
	<Transition name="t-burger-menu" duration="1000" appear>
		<div
			v-if="menuIsOpen"
			class="c-burger-menu relative bg-white h-full overflow-hidden"
		>
			<ScopedColorTheme class="h-full" :theme="activeTheme">
				<!-- Lens Flare -->
				<LensFlare
					v-if="menuIsOpen"
					id="c-burger-menu__flare"
					:class="[
						'c-burger-menu__lens-flare',
						'absolute bottom-0 right-0',
						'<1024:top-0 <1024:bottom-auto',
						'w-1/2',
					]"
					:angle="flareAngle"
					:min-blur-amount="15"
					:max-blur-amount="15"
					:flares="[
						{
							size: 1000,
							offset: 300,
							opacity: 1,
							theme: 'primary',
							classes: [
								'c-burger-menu__flare-first',
								'duration-1000 ease-smooth-out',
							],
						},
						{
							size: 500,
							offset: -200,
							opacity: 0.8,
							theme: 'secondary',
							classes: [
								'c-burger-menu__flare-second',
								'duration-1000 ease-smooth-out',
							],
						},
					]"
				/>

				<div
					:class="[
						'relative max-w-layout-max',
						'mx-auto h-full overflow-y-scroll',
						'px-layout-margin pt-xl pb-md >=1024:pt-lg >=1024:pb-4xl',
						'flex flex-col justify-between',
					]"
				>
					<div>
						<!-- Top -->
						<div
							:class="[
								'hidden >=1024:flex',
								'justify-between items-center',
							]"
						>
							<BaseButton
								ref="closeButtonDesktop"
								dark
								class="order-2"
								tabindex="1"
								@click="closeOverlay"
							>
								<template #default>
									<span v-text="'LUK'"></span>
								</template>

								<template #icon>
									<SvgClose class="w-14" />
								</template>
							</BaseButton>

							<BaseH4
								tag="h2"
								class="mb-3"
								v-text="'I Odsherred kan du'"
							/>
						</div>

						<!-- Content -->
						<div
							:class="[
								'relative',
								'flex >=1024:grid >=1024:grid-cols-12',
								'gap-x-layout-gutter >=1024:mt-3xl',
								'duration-500 ease-smooth-out',
							]"
							:style="{
								height: contentHeight
									? `${contentHeight}px`
									: 'auto',
							}"
						>
							<!-- Parent items -->
							<TransitionExt
								name="burger-menu-parent"
								:duration="500"
								@enter="updateContentHeight"
							>
								<!--
								Currently setting >=1024:block to
								overwrite v-show on desktop, it's a
								bit hacky, but thought it were preferable
								compared to using JavaScript to check
								window width.
							-->
								<div
									v-show="!activeItem"
									ref="parentItems"
									:class="[
										'w-5/5col absolute top-0 left-0',
										'>=1024:block >=1024:relative >=1024:w-auto',
										'>=1024:col-start-1 >=1024:col-end-6',
									]"
								>
									<BaseH4
										tag="h2"
										class="mb-3xl >=1024:hidden"
										v-text="'I Odsherred kan du'"
									/>

									<ul class="space-y-lg">
										<li
											v-for="(item, index) in main"
											:key="`main-item-${index}`"
										>
											<button
												:aria-controls="`menu-children-${item.id}`"
												:aria-expanded="
													String(
														!!(
															activeItem &&
															activeItem.id ===
																item.id
														)
													)
												"
												:class="[
													'flex items-center',
													'text-menu-category font-darker-grotesque',
													'duration-500 ease-smooth-out',
													'transform group text-left',

													{
														'opacity-60 hover:opacity-100':
															activeItem &&
															activeItem.id !==
																item.id,
													},
												]"
												:tabindex="
													getItemTabindex(item)
												"
												@click="
													() => setActiveItem(item)
												"
											>
												<BurgerMenuArrow
													class="flex-shrink-0"
													:active="
														activeItem &&
														activeItem.id ===
															item.id
													"
												/>
												<span
													:class="[
														'mb-6 transform',
														'transition-transform duration-500 ease-smooth-out',

														{
															'translate-x-20':
																activeItem &&
																activeItem.id ===
																	item.id,
															'group-hover:translate-x-20':
																!activeItem ||
																activeItem.id !==
																	item.id,
														},
													]"
													v-html="item.title"
												></span>
											</button>
										</li>
									</ul>
								</div>
							</TransitionExt>

							<!-- Children -->
							<div
								:class="[
									'w-5/5col',
									'>=1024:relative >=1024:w-auto',
									'>=1024:col-start-7 >=1024:col-end-11',
								]"
							>
								<TransitionExt
									name="burger-menu-children"
									:duration="500"
									@enter="updateContentHeight"
								>
									<div
										v-if="activeItem"
										:id="`menu-children-${activeItem.id}`"
										ref="childrenItems"
										:key="`children-${activeItem.id}`"
										:class="[
											'w-full absolute top-0 left-0',
											'duration-500 ease-smooth-out',
											'>=1024:pt-sm',
										]"
									>
										<button
											:class="[
												'flex items-center gap-x-sm',
												'>=1024:hidden mb-2xl',
											]"
											tabindex="1"
											@click="() => setActiveItem(null)"
										>
											<SvgCaret
												class="w-12 transform rotate-90"
											/>

											<div
												:class="[
													'text-h4 mb-3',
													'font-darker-grotesque font-bold',
												]"
												v-text="'Tilbage'"
											></div>
										</button>

										<h2
											:class="[
												'>=1024:hidden mb-2xl',
												'text-menu-category font-medium',
												'font-darker-grotesque',
											]"
											v-html="activeItem.title"
										></h2>

										<ul class="space-y-md">
											<li
												v-for="(
													child, index
												) in activeItem.children"
												:key="`child-${index}`"
												:class="[
													'flex gap-x-8 items-center',
													'text-h3 font-darker-grotesque',
													'duration-500 ease-smooth-out',

													{
														'transform hover:translate-x-8':
															child.id !== pageId,
													},
												]"
											>
												<SvgCaret
													v-if="child.id === pageId"
													:class="[
														'flex-shrink-0 text-primary w-12',
														'duration-500 ease-smooth-out',
														'transform -rotate-90',
													]"
												/>

												<NuxtLinkExt
													:to="child.url"
													:target="child.target"
													class="mb-3"
													tabindex="1"
													v-html="child.title"
												/>
											</li>
										</ul>
									</div>
								</TransitionExt>
							</div>
						</div>
					</div>

					<!-- Bottom -->
					<div
						:class="[
							'c-burger-menu__bottom',
							'grid grid-cols-1 gap-sm max-w-fit mt-2xl',
							'>=1024:flex >=1024:flex-wrap >=1024:w-5/12col',
						]"
					>
						<NuxtLinkExt
							v-for="(item, index) in computedSecondary"
							:key="`secondary-${index}`"
							:to="item.url"
							:target="item.target"
							tabindex="2"
						>
							<BaseButton
								:dark="!item.isExternal"
								:light="item.isExternal"
								class="<1024:h-48 <1024:min-w-full"
							>
								<template #default>
									<div
										class="mb-1 >=1024:mb-0"
										v-html="item.title || item.name"
									></div>
								</template>
								<template #icon>
									<SvgArrow
										v-if="item.isExternal"
										class="transform -rotate-45"
									/>
								</template>
							</BaseButton>
						</NuxtLinkExt>

						<BaseButton
							ref="closeButtonMobile"
							dark
							:class="[
								'c-burger-menu__close-mobile w-48 h-48 >=1024:hidden',
								'fixed bottom-md right-layout-margin',
							]"
							aria-label="Luk menu"
							tabindex="2"
							@click="closeOverlay"
						>
							<template #icon>
								<SvgClose class="w-14" />
							</template>
						</BaseButton>
					</div>
				</div>
			</ScopedColorTheme>
		</div>
	</Transition>
</template>

<script>
import { mapState } from 'vuex';

import ScopedColorTheme from '~/components/shared/ScopedColorTheme';
import LensFlare from '~/components/shared/LensFlare';
import BurgerMenuArrow from '~/components/shared/BurgerMenuArrow';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';
import SvgClose from '~/assets/svgs/icon-close.svg?inline';

export default {
	name: 'BurgerMenu',

	components: {
		ScopedColorTheme,
		LensFlare,
		BurgerMenuArrow,
		SvgArrow,
		SvgCaret,
		SvgClose,
	},

	props: {
		menuIsOpen: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const { navigation } = this.$store.state.site || {};

		return {
			flareAngle: 20,
			flareAngles: navigation.main?.map(
				(_, n) => Math.sin(n * 0.5) * 20 + 10
			),

			contentHeight: null,
			activeItem: null,
			activeTheme: 'blue',

			main: null,
			secondary: null,
			...navigation,
		};
	},

	computed: {
		...mapState(['pageId']),

		activeItemIndex() {
			return this.main?.indexOf?.(this.activeItem) ?? 0;
		},

		computedSecondary() {
			return this.secondary
				.map((e) => ({
					...e,
					isExternal: this.isExternal(e.url),
				}))
				.sort(({ isExternal: a }, { isExternal: b }) => {
					return a === b ? 0 : a && !b ? 1 : -1;
				});
		},
	},

	watch: {
		'$route.fullPath'() {
			this.closeOverlay(false);
		},
		menuIsOpen(isOpen) {
			if (isOpen) {
				this.$nextTick(() => {
					const { closeButtonMobile, closeButtonDesktop } =
						this.$refs;
					closeButtonMobile?.clientWidth &&
						closeButtonMobile?.focus();
					closeButtonDesktop?.clientWidth &&
						closeButtonDesktop?.focus();
				});
			}
		},
	},

	mounted() {
		window.addEventListener('resize', this.onResize);
		this.onResize();

		const activeItem = this.main.find((parent) => {
			const onParent = parent.id === this.pageId;
			const onChild =
				this.$route.path.slice(0, parent.url.length) === parent.url;
			return onParent || onChild;
		});

		this.activeItem =
			activeItem || (window.innerWidth >= 1024 ? this.main[0] : null);
		this.activeTheme = activeItem?.theme?.label ?? 'blue';
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		onResize() {
			this.updateContentHeight(
				this.activeItem
					? this.$refs?.childrenItems
					: this.$refs?.parentItems
			);
		},

		updateContentHeight(element) {
			if (element && window.innerWidth < 1024) {
				const { height } = element.getBoundingClientRect();
				return (this.contentHeight = height);
			}

			this.contentHeight = null;
		},

		setActiveItem(item) {
			this.activeItem = item;
			this.activeTheme = item?.theme?.label;
			this.flareAngle = this.flareAngles[this.main.indexOf(item)] ?? 0;
		},
		getItemTabindex(item) {
			return this.main.indexOf(item) > this.activeItemIndex ? 2 : 1;
		},

		closeOverlay(goToMenuButton = true) {
			this.$emit('close');

			if (goToMenuButton) {
				setTimeout(() => {
					const menuButtonDesktop =
						document.querySelector('#desktop-menu');
					const menuButtonMobile =
						document.querySelector('#mobile-menu');
					menuButtonDesktop?.clientWidth &&
						menuButtonDesktop?.focus();
					menuButtonMobile?.clientWidth && menuButtonMobile?.focus();
				}, 500);
			}
		},

		/** Helper functions */
		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}

				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},
	},
};
</script>

<style lang="postcss">
@screen <1024 {
	.c-burger-menu__bottom .c-base-button__inner {
		@apply justify-center px-2xl;
	}

	.c-burger-menu__close-mobile .c-base-button__inner {
		@apply w-48 h-48 !important;
	}
}

.c-burger-menu__lens-flare {
	transform: translateY(-64%) rotate(55deg) scaleX(-1);
}

@screen >=1024 {
	.c-burger-menu__lens-flare {
		transform: translateY(70%) !important;
	}
}

.t-burger-menu-parent-enter-active,
.t-burger-menu-parent-leave-active,
.t-burger-menu-children-enter-active,
.t-burger-menu-children-leave-active {
	@apply transform ease-smooth-out;
}

.t-burger-menu-parent-enter,
.t-burger-menu-parent-leave-to {
	@apply -translate-x-full opacity-0;
}

.t-burger-menu-children-enter,
.t-burger-menu-children-leave-to {
	@apply translate-x-full opacity-0;
}

@screen >=1024 {
	.t-burger-menu-parent-enter,
	.t-burger-menu-parent-leave-to {
		@apply translate-x-0 opacity-100;
	}

	.t-burger-menu-children-enter {
		@apply -translate-x-52 opacity-0;
	}

	.t-burger-menu-children-leave-to {
		@apply translate-x-52 opacity-0;
	}
}

#c-burger-menu__flare {
	z-index: initial;
}

@screen <1024 {
	#c-burger-menu__flare {
		right: -10% !important;
	}
}

.c-burger-menu__flare-first {
	animation: burgerMenuFlare 5000ms ease-in-out infinite;
}

.c-burger-menu__flare-second {
	animation: burgerMenuFlare 5000ms ease-in-out infinite;
	animation-delay: 1000ms;
}

@media (prefers-reduced-motion) {
	.c-burger-menu__flare-first,
	.c-burger-menu__flare-second {
		animation: unset;
	}
}
.reduced-motion {
	.c-burger-menu__flare-first,
	.c-burger-menu__flare-second {
		animation: unset;
	}
}

@keyframes burgerMenuFlare {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-48px);
	}
}

/* Burger menu transition */
.t-burger-menu-enter-active,
.t-burger-menu-leave-active {
	@apply duration-700 ease-smooth-out;
}

.t-burger-menu-enter,
.t-burger-menu-leave-to {
	transform: translateY(-100vh);
}

@screen >=1024 {
	.t-burger-menu-enter-active .c-burger-menu__lens-flare {
		@apply duration-500 ease-smooth-out delay-200;
	}
	.t-burger-menu-leave-active .c-burger-menu__lens-flare {
		@apply duration-500 ease-smooth-out;
	}

	.t-burger-menu-enter .c-burger-menu__lens-flare,
	.t-burger-menu-leave-to .c-burger-menu__lens-flare {
		transform: translateY(100%) !important;
	}
}

/**
	Lens flare transition
 */
/* @screen >=1024 {
	.t-burger-menu__flare-enter-active {
		@apply duration-1000 ease-smooth-out;
		transition-delay: 600ms;
	}
	.t-burger-menu__flare-leave-active {
		@apply duration-200 ease-smooth-out;
	}
	.t-burger-menu__flare-enter,
	.t-burger-menu__flare-leave-to {
		@apply opacity-0;
		transform: translateY(90%) !important;
	}
} */
</style>
