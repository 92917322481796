<template>
	<span
		class="c-site-header-logo"
		:class="{
			'c-site-header-logo--collapsed': collapse,
		}"
	>
		<Component
			:is="to ? 'NuxtLinkExt' : 'span'"
			class="relative inline-flex items-baseline w-full min-w-fit h-full"
			:to="to"
			:style="{
				paddingLeft: `${Math.round((62 / 163) * 10000) / 100}%`,
			}"
			:tabindex="tabindex"
			data-focus=".c-site-header-logo__coat-of-arms"
		>
			<!--
				Two coats of arms are drawn, one of which
				the chariot is the only thing visible, and
				one with the rest.
			-->
			<template v-for="index in 2">
				<SvgCoatOfArms
					:key="index"
					class="
						c-site-header-logo__coat-of-arms
						absolute
						left-0
						transition-all
						duration-500
					"
					:style="{
						top: '-45%',
						width: `${Math.round((46 / 163) * 10000) / 100}%`,
						...(collapse
							? {
									transform: 'translateY(-12%)',
							  }
							: {}),
					}"
				/>
			</template>

			<!--
				We insert the text only once, but we
				make sure to wrap "Kommune" in a span
			-->
			<span
				class="
					inline-block
					font-darker-grotesque font-bold
					text-logo
					uppercase
					transition-all
					duration-500
				"
			>
				Odsherred
				<br />
				<span
					class="absolute transition-opacity duration-500"
					:class="
						collapse && 'opacity-0 select-none pointer-events-none'
					"
					:aria-hidden="collapse ? 'true' : null"
				>
					Kommune
				</span>
			</span>
		</Component>
	</span>
</template>

<script>
import SvgCoatOfArms from '~/assets/svgs/coat-of-arms.svg?inline';

export default {
	name: 'SiteHeaderLogo',

	components: {
		SvgCoatOfArms,
	},

	props: {
		to: [String, Object],
		collapse: Boolean,
		tabindex: {
			type: [String, Number],
			default: null,
		},
	},
};
</script>

<style lang="postcss">
.c-site-header-logo {
	@apply inline-block;
	width: clamp(
		163px,
		163px +
			(27 / (1440 - 375) * (var(--visual-viewport-width, 100vw) - 163px)),
		190px
	);
}

/* Coat of arms */
.c-site-header-logo__coat-of-arms:first-child {
	clip-path: inset(0 0 0% 0);

	& :last-child {
		visibility: hidden;
	}
}
.c-site-header-logo__coat-of-arms:nth-child(2) {
	& :where(:first-child, :nth-child(2), :nth-child(3), :nth-child(4)) {
		visibility: hidden;
	}
}
.c-site-header-logo--collapsed .c-site-header-logo__coat-of-arms:first-child {
	clip-path: inset(0 0 100% 0);
	opacity: 0;
}
</style>
