<template>
	<div class="c-global-overlay">
		<PortalTarget
			:id="`c-global-overlay-${_uid}`"
			ref="portalTarget"
			v-trap-focus.loop="{
				active: hasContent,
			}"
			multiple
			:name="name"
			:class="[
				'c-global-overlay__target z-50',
				{ 'c-global-overlay__target--active': hasContent },
			]"
			@change="onChange"
		/>
	</div>
</template>

<script>
import trapFocus from '~/citi-baseline/directives/trap-focus.directive.js';

export default {
	name: 'GlobalOverlay',

	directives: {
		trapFocus,
	},

	props: {
		name: {
			type: String,
			default: 'overlay',
		},
	},

	data() {
		return {
			hasContent: false,
		};
	},

	methods: {
		onChange() {
			this.$nextTick(() => {
				/**
				 * Check whether or not the overlay has
				 * any content, taking transitions, ie.
				 * the -leave-active class into consideration,
				 * and using this to block scroll while
				 * the overlay is active.
				 */
				const child = document.querySelector(
					`#c-global-overlay-${this._uid} > *`
				);

				const classes = child?.classList || [];
				const leaving = Array.from(classes).reduce((acc, cur) => {
					return acc || cur.indexOf('-leave-active') !== -1;
				}, false);

				/*
					This part has been extended to set the
					overflow of the html-tag to scroll to
					keep the scrollbars around when the
					overlay is showing.
					To make this work, we need to move the
					scroll position between the html-tag and
					the body-tag.
				*/

				const scrollTop = Math.max(
					document.documentElement.scrollTop,
					document.body.scrollTop
				);
				this.hasContent = !!(child && !leaving);
				document.body.style.overflow = this.hasContent
					? 'hidden'
					: null;
				document.documentElement.style.overflowY = this.hasContent
					? 'scroll'
					: null;

				if (this.hasContent) {
					document.body.scrollTop = scrollTop;
				} else {
					document.documentElement.scrollTop = scrollTop;
				}

				this.$emit('change', this.hasContent);
			});
		},
	},
};
</script>

<style lang="postcss">
.c-global-overlay__target {
	@apply fixed top-0 left-0 w-visual-screen h-screen;
	@apply pointer-events-none;
}

.c-global-overlay__target--active > * {
	@apply pointer-events-auto;
}
</style>
