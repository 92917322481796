var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.searchPage)?_c('div',{staticClass:"c-site-header-search"},[_c('div',{staticClass:"relative inline-flex w-full border-current rounded-full isolate",class:{
			'rounded-b-none rounded-t-24 >=768:rounded-t-30 border-b-0':
				_vm.isAutoCompleteOpen,
		},staticStyle:{"height":"inherit","border-width":"1.5px"}},[_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"500"}},[(_vm.showInput)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],ref:"input",class:[
					'absolute left-0 top-0 w-full h-full rounded-full',
					'pl-xl pr-48 pb-4 appearance-none align-middle',
					'font-darker-grotesque font-semibold text-input leading-1 placeholder-text-placeholder',
					'<360:text-input-sm',
				],attrs:{"type":"search","role":"searchbox","aria-autocomplete":_vm.useAutosuggest ? 'list' : null,"aria-controls":_vm.useAutosuggest
						? `c-site-header-search-${_vm._uid}__auto-complete`
						: null,"placeholder":"Hvad kan vi hjælpe dig med?"},domProps:{"value":(_vm.searchText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.triggerSearch.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}):_vm._e()]),_vm._v(" "),_c(_vm.buttonBindings.is,_vm._b({tag:"Component",staticClass:"c-site-header-search__button",class:[
				'relative rounded-full overflow-hidden',
				'ml-auto max-w-full h-full',
				_vm.whiteBackground ? 'bg-white' : 'bg-transparent',
				'flex justify-center items-center',
				'transition-all duration-500 isolate',
				'h-full',
			],staticStyle:{"aspect-ratio":"1"},on:{"click":_vm.triggerSearch}},'Component',_vm.buttonBindings,false),[_c('SvgIconSearch',{staticClass:"w-16 h-16 >=1024:w-15 >=1024:h-15"})],1)],1),_vm._v(" "),(_vm.useAutosuggest)?[_c('AutoCompleteListCustom',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInput),expression:"showInput"}],staticClass:"relative -mt-2 w-full z-10",attrs:{"id":`c-site-header-search-${_vm._uid}__auto-complete`},on:{"search-suggestion":_vm.triggerSearchWithSuggestion,"state-change":function($event){_vm.isAutoCompleteOpen = $event}}})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }