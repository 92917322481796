<template>
	<Component
		:is="tag"
		class="c-scoped-color-theme"
		:class="{
			[`c-scoped-color-theme--${mappedTheme}`]: mappedTheme,
		}"
		:style="{
			...(mappedTheme
				? {
						'--theme-colors-primary': `var(--color-theme-${solution}-${
							mappedTheme === 'prism'
								? currentPrismTheme
								: mappedTheme
						}-primary)`,
						'--theme-colors-primary-dark': `var(--color-theme-${solution}-${
							mappedTheme === 'prism'
								? currentPrismTheme
								: mappedTheme
						}-primary-dark)`,
						'--theme-colors-secondary': `var(--color-theme-${solution}-${
							mappedTheme === 'prism'
								? currentPrismTheme
								: mappedTheme
						}-secondary)`,
				  }
				: {}),
			'--scoped-color-theme-duration': `${partialPrismDuration}ms`,
		}"
	>
		<slot></slot>
	</Component>
</template>

<script>
import { mapGetters } from 'vuex';

// Important that they are in hue-order
const themes = [
	'blue',
	'purple',
	'red',
	'orange',
	'yellow',
	'green',
	'turquoise',
];

const themeRemap = {
	// main remaps
	'colour-theme-2': 'orange',
	'colour-theme-1': 'blue',
	'colour-theme-3': 'yellow',
	'colour-theme-4': 'purple',
	'colour-theme-5': 'turquoise',
	'colour-theme-6': 'green',
	'colour-theme-7': 'red',
	// Remove from here when dubble checked
	'Colour-Theme-2': 'orange',
	'Colour-Theme-1': 'blue',
	'Colour-Theme-3': 'yellow',
	'Colour-Theme-4': 'purple',
	'Colour-Theme-5': 'turquoise',
	'Colour-Theme-6': 'green',
	'Colour-Theme-7': 'red',

	// subsite remaps
	blue: 'blue',
	purple: 'purple',
	red: 'red',
	orange: 'orange',
	yellow: 'yellow',
	green: 'green',
	turquoise: 'turquoise',
};

export default {
	name: 'ScopedColorTheme',

	props: {
		tag: {
			type: String,
			default: 'div',
		},
		theme: {
			type: String,
			validator(value) {
				return [
					...themes,
					...Object.keys(themeRemap),
					'prism',
				].includes(value);
			},
		},
		prismDuration: {
			type: Number,
			default: themes.length * 3000,
		},
	},

	data() {
		return {
			prismRotationInterval: null,
			currentPrismTheme: this.theme === 'prism' ? themes[0] : this.theme,
		};
	},

	computed: {
		partialPrismDuration() {
			return Math.ceil(this.prismDuration / themes.length);
		},

		mappedTheme() {
			return !themes.includes(this.theme) && this.theme !== 'prism'
				? themeRemap[this.theme]
				: this.theme;
		},

		...mapGetters(['solution']),
	},

	watch: {
		theme: {
			immediate: true,
			handler() {
				if (process.client) {
					if (this.theme === 'prism') {
						this.updatePrismColor();
						this.$nextTick(() => {
							this.prismRotationInterval = window.setInterval(
								this.updatePrismColor,
								this.partialPrismDuration
							);
						});
					} else {
						window.clearInterval(this.prismRotationInterval);
					}
				}
			},
		},
	},

	beforeDestroy() {
		window.clearInterval(this.prismRotationInterval);
	},

	methods: {
		updatePrismColor() {
			const isReduced =
				this.$store.state.isReducedMotion ||
				window.matchMedia(`(prefers-reduced-motion: reduce)`) ===
					true ||
				window.matchMedia(`(prefers-reduced-motion: reduce)`)
					.matches === true ||
				document.body.classList.contains('reduced-motion');
			const nextIndex = isReduced
				? themes.indexOf(this.currentPrismTheme)
				: (themes.indexOf(this.currentPrismTheme) + 1) % themes.length;
			this.currentPrismTheme = themes[nextIndex];
		},
	},
};
</script>
