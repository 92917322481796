var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Transition',{attrs:{"name":"t-search-longread","duration":600}},[(_vm.groups.length)?_c('div',{class:[
			'c-search-longread',
			'w-full max-h-60 >=768:max-h-80 h-80',
			'bg-white shadow-30',
		]},[_c('div',{class:[
				'c-search-longread__inner',
				'max-w-layout-max mx-auto px-layout-margin',
				'w-full h-full flex items-center',
			]},[_c('Transition',{attrs:{"name":"t-search-section-long-read__items","mode":"out-in"}},[_c('div',{key:_vm.key,ref:"scroller",class:[
						'relative h-full',
						'pointer-events-auto <768:overflow-x-scroll',
						'<768:-mx-layout-margin mb-3',
					],on:{"scroll":_vm.onInnerScroll}},[_c('div',{key:_vm.key,class:[
							'c-search-section-long-read__indicator',
							'rounded-full bg-text h-32 z-0',
							'absolute top-1/2 left-0',
							'duration-200 ease-silk-out',
						],style:(_vm.indicatorStyle)}),_vm._v(" "),_c('div',{class:[
							'c-search-section-long-read__items',
							'h-full >=768:max-w-10/12col',
							'min-w-fit whitespace-nowrap <768:px-layout-margin',
							'flex flex-nowrap gap-x-12 >=768:gap-x-20 items-center',
						]},_vm._l((_vm.groups),function(group,index){return _c('a',{key:`longread-${index}`,ref:"buttons",refInFor:true,class:[
								'text-label-card z-10 px-xs',
								'font-darker-grotesque font-semibold',
								'white-space-nowrap min-w-fit transition-colors duration-200 ease-silk-out',
								{
									'text-white': index === _vm.activeGroup,
								},
							],attrs:{"href":`#${group.targetId}`,"aria-current":_vm.activeGroup === index ? 'true' : null},on:{"click":function($event){$event.preventDefault();return (() => _vm.onClick(group.targetId)).apply(null, arguments)}}},[_c('span',{domProps:{"textContent":_vm._s(group.name)}}),_vm._v(" "),_c('span',{class:[
									'ml-4',
									{ 'opacity-70': index !== _vm.activeGroup },
								],domProps:{"textContent":_vm._s(group.total)}})])}),0)])])],1),_vm._v(" "),_c('Transition',{attrs:{"name":"t-search-section-long-read__gradient"}},[(_vm.canScrollLeft)?_c('div',{class:[
					'c-search-section-long-read__gradient-left',
					'flex justify-start items-center pl-layout-margin',
					'pointer-events-auto >=768:hidden',
				]},[_c('BaseButton',{staticClass:"w-24 h-24",attrs:{"light":"","aria-label":"Scroll tilbage"},on:{"click":_vm.scrollToPrevious},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{class:[
								'w-10',
								'transform rotate-90 -translate-x-2',
							]})]},proxy:true}],null,false,612654672)})],1):_vm._e()]),_vm._v(" "),_c('Transition',{attrs:{"name":"t-search-section-long-read__gradient"}},[(_vm.canScrollRight)?_c('div',{class:[
					'c-search-section-long-read__gradient-right',
					'flex justify-start items-center pl-layout-margin',
					'pointer-events-auto >=768:hidden',
				]},[_c('BaseButton',{staticClass:"w-24 h-24 z-50",attrs:{"light":"","aria-label":"Scroll frem"},on:{"click":_vm.scrollToNext},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{class:[
								'w-10',
								'transform rotate-90 -translate-x-2',
							]})]},proxy:true}],null,false,612654672)})],1):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }