<template>
	<svg
		:id="id"
		aria-hidden="true"
		focusable="false"
		class="c-lens-flare"
		overflow="visible"
		viewBox="0 0 1000 1000"
	>
		<Transition name="t-lens-flare" appear>
			<g v-if="initialised" class="c-lens-flare__inner">
				<g
					class="duration-1000 c-lens-flare__change ease-smooth-out"
					:style="`transform: rotate(${angle}deg); transform-origin: center`"
				>
					<!--
						Note: Do not remove the width and height, even though
						they seem quite arbitrary. It's a bit hacky fix to
						some serious performance-issues in Safari.
					-->
					<filter :id="`filter-${id}`" width="50" height="50">
						<feGaussianBlur :stdDeviation="minBlurAmount" />
					</filter>

					<g
						v-for="(flare, index) in flares"
						:key="`flare-${index}`"
						:style="flareStyles[index]"
						class="c-lens-flare__change"
					>
						<circle
							v-bind="flare.attrs || []"
							:filter="`url(#filter-${id})`"
							:r="flareSizes[index] / 2"
							fill="currentColor"
							cx="500"
							cy="500"
							:class="[
								'c-lens-flare__element',
								`c-lens-flare__element--${flare.theme}`,
								...[flare.classes || []].flat(),
							]"
						/>
					</g>
				</g>
			</g>
		</Transition>
	</svg>
</template>

<script>
let resizeObserver = null;
if (typeof window !== 'undefined') {
	resizeObserver = new ResizeObserver((entries) => {
		entries
			.map((entry) => entry?.target)
			.filter(Boolean)
			.forEach((target) => {
				[...(target?.children ?? [])]
					.filter((el) => el?.classList?.contains?.('c-lens-flare'))
					.forEach((el) => {
						el.__vue__?.onScroll?.();
					});
			});
	});
}

export default {
	name: 'LensFlare',

	props: {
		id: {
			type: String,
			required: true,
		},

		/**
		 * An array of objects with the following properties:
		 * - size (Number): The size of the flare in pixels.
		 * - offset (Number): The relative offset of the flare in pixels.
		 * - opacity (Number): The opacity of the flare. Will default to 1.
		 * - parallax (Number): Modifier multiplied with the different parallax values. Will default to 1.
		 * - theme (String): The theme of the flare ('primary' or 'secondary'). Will default to 'primary'.
		 * - attrs (Object): Extra attributes to add to the individual flare. Will default to '{}'.
		 */
		flares: {
			type: Array,
			default: () => [],
			validator: (value) =>
				value.reduce(
					(acc, cur) => acc && cur.size != null && cur.offset != null,
					true
				),
		},

		angle: {
			type: Number,
			default: 0,
		},

		minBlurAmount: {
			type: Number,
			default: 20,
		},

		maxBlurAmount: {
			type: Number,
			default: 30,
		},

		easing: {
			type: Function,
			default: (x) => x ** 2,
		},
	},

	data() {
		return {
			initialised: false,
			observer: null,
			isInView: false,

			blurAmount: this.maxBlurAmount,
			actualPercentage: 0,
			percentage: 0,

			requestedAnimationFrame: null,
		};
	},

	computed: {
		flareStyles() {
			return this.flares.map(({ offset, targetOffset, opacity }) => {
				const offsetDiff = (targetOffset ?? offset) - offset;
				const offsetX = offset + offsetDiff * this.percentage;
				const translate = `translate3d(${offsetX}px, 0, 0)`;
				return `transform: ${translate}; opacity: ${opacity};`;
			});
		},
		flareSizes() {
			return this.flares.map((flare) => {
				const { size, targetSize } = flare;
				const sizeDiff = (targetSize ?? size) - size;
				return Math.max(size + sizeDiff * this.percentage, 0);
			});
		},
	},

	mounted() {
		if (
			this.flares.reduce(
				(acc, cur) =>
					cur.targetOffset !== cur.offset ||
					cur.targetSize !== cur.size ||
					acc,
				false
			)
		) {
			const isReducedMotion =
				this.$store.state.isReducedMotion ||
				window.matchMedia(`(prefers-reduced-motion: reduce)`) ===
					true ||
				window.matchMedia(`(prefers-reduced-motion: reduce)`)
					.matches === true ||
				document.body.classList.contains('reduced-motion');
			if (!isReducedMotion) {
				resizeObserver.observe(this.$el.parentElement);
				window.addEventListener('scroll', this.onScroll, {
					passive: true,
				});
			}
		}

		this.initialise();
	},

	beforeDestroy() {
		resizeObserver.unobserve(this.$el);
		window.removeEventListener('scroll', this.onScroll);
		this.observer.disconnect();
	},

	methods: {
		initialise() {
			this.observer = new IntersectionObserver(
				([{ isIntersecting }]) => {
					this.isInView = isIntersecting;
				},
				{ threshold: 0 }
			);

			this.observer.observe(this.$el);
			this.onScroll(null, true);
			this.initialised = true;
		},

		onScroll(e, forced = false) {
			if (this.isInView || forced) {
				window.cancelAnimationFrame(this.requestedAnimationFrame);
				this.requestedAnimationFrame = window.requestAnimationFrame(
					() => {
						const { top, height } =
							this.$el.getBoundingClientRect();
						const lowerBound = top - innerHeight;
						const upperBound = Math.max(top + height, 0);
						const boundDifference = upperBound - lowerBound;

						this.actualPercentage =
							1 - upperBound / boundDifference;
						this.percentage = this.easing(this.actualPercentage);

						const { maxBlurAmount, minBlurAmount } = this;
						const blurDiff = maxBlurAmount - minBlurAmount;
						this.blurAmount =
							blurDiff * this.percentage + minBlurAmount;
					}
				);
			}
		},
	},
};
</script>
a

<style lang="postcss">
:where(.c-lens-flare) {
	contain: style layout;
	pointer-events: none;
	position: relative;
	z-index: -1;
}

:where(.c-lens-flare__change) {
	will-change: transform;
}

:where(.c-lens-flare__element) {
	@apply transition-colors duration-color-theme ease-linear;
}

:where(.c-lens-flare__element--primary) {
	contain: style paint layout;
	@apply text-primary;
}

:where(.c-lens-flare__element--secondary) {
	contain: style paint layout;
	@apply text-secondary;
}

.t-lens-flare-enter-active,
.t-lens-flare-leave-active {
	@apply duration-1000 ease-smooth-out;
}

.t-lens-flare-enter,
.t-lens-flare-leave-to {
	@apply opacity-0;
}
</style>
