var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"c-site-header",attrs:{"inert":_vm.menuIsOpen ? true : null}},[_c('div',{staticClass:"top-0 bg-white filter transition-all duration-500",class:[
			_vm.keepAtTop
				? 'absolute -left-layout-margin -right-layout-margin'
				: 'fixed left-0 w-full',
			{
				'h-site-header bg-opacity-0 drop-shadow-none':
					!_vm.isSticky && !_vm.onSearchPage,
				'h-site-header-compact drop-shadow-14 >=1024:drop-shadow-20':
					_vm.isSticky && !_vm.onSearchPage,
				'h-80 >=768:h-site-header bg-opacity-0': _vm.onSearchPage,
			},
		]},[_c('div',{staticClass:"w-full h-full",style:(_vm.contentStyle)},[(_vm.onSearchPage)?_c('SearchLongread',{staticClass:"absolute top-full"}):_vm._e(),_vm._v(" "),_c('div',{class:[
					'relative w-full h-full z-10',
					'transition-all duration-500',
					{ 'pb-xl >=1024:pb-0': !_vm.isSticky && !_vm.onSearchPage },
					{ 'bg-transparent': !_vm.onSearchPage },
					{ 'bg-white': _vm.onSearchPage },
				]},[_c('div',{staticClass:"w-full h-full max-w-layout-max px-layout-margin mx-auto flex items-center justify-end gap-xl"},[_c('SiteHeaderLogo',{class:[
							'flex-shrink-0 mr-auto',
							'duration-500',

							{
								'opacity-0': _vm.onSearchPage,
								'delay-500': !_vm.onSearchPage,
							},
						],attrs:{"to":_vm.url,"collapse":_vm.isSticky && !_vm.onSearchPage,"tabindex":_vm.onSearchPage ? -1 : null,"inert":_vm.onSearchPage ? true : null}}),_vm._v(" "),(_vm.navigation)?_c('nav',{class:[
							'flex-shrink-0 flex items-center gap-sm',
							'duration-500',

							{
								'opacity-0 invisible': _vm.onSearchPage,
								'delay-500': !_vm.onSearchPage,
							},
						],attrs:{"aria-label":"hurtig bar","inert":_vm.onSearchPage ? true : null}},[(
								_vm.isLoggedIn &&
								_vm.editorLink &&
								_vm.editorLink.url &&
								_vm.backendUrl
							)?[_c('a',{staticClass:"font-darker-grotesque font-semibold text-button-xs uppercase",attrs:{"href":`${_vm.backendUrl}${_vm.editorLink.url}`,"target":"_blank"},domProps:{"innerHTML":_vm._s(_vm.editorLink.title)}}),_vm._v(" "),_c('span',{staticClass:"c-site-header__menu-separator"})]:_vm._e(),_vm._v(" "),(
								_vm.navigation.focusItem &&
								_vm.navigation.focusItem.url
							)?[_c('NuxtLinkExt',{staticClass:"font-darker-grotesque font-semibold text-button-xs uppercase",attrs:{"to":_vm.navigation.focusItem.url},domProps:{"textContent":_vm._s(_vm.navigation.focusItem.name)}}),_vm._v(" "),_c('span',{staticClass:"c-site-header__menu-separator"})]:_vm._e(),_vm._v(" "),(
								_vm.navigation.languages &&
								_vm.navigation.languages.length
							)?[_c('LanguagePicker',{attrs:{"items":_vm.navigation.languages}}),_vm._v(" "),_c('span',{staticClass:"c-site-header__menu-separator"})]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{class:[
							'flex-shrink-0',
							'hidden >=768:inline-flex',
							'justify-end items-center gap-sm',
							'duration-500 w-176',

							{
								'w-full gap-5xl': _vm.onSearchPage,
								'delay-300': !_vm.onSearchPage,
							},
						]},[(_vm.searchPage)?_c('SiteHeaderSearch',{class:[
								'flex-shrink-0 flex-1',
								'duration-300',

								{
									'h-60 delay-500': _vm.onSearchPage,
								},
							],attrs:{"use-autosuggest":"","show-input":_vm.keepAtTop || _vm.onSearchPage,"button-mode":_vm.keepAtTop || _vm.onSearchPage
									? 'button'
									: 'link'},nativeOn:{"focusin":function($event){_vm.scrollPercentage = 0}}}):_vm._e(),_vm._v(" "),_c(_vm.onSearchPage ? 'NuxtLinkExt' : 'div',{tag:"Component",attrs:{"to":_vm.previousPath}},[(_vm.navigation || _vm.onSearchPage)?_c('BaseButton',{staticClass:"c-site-header__button flex-shrink-0 whitespace-nowrap w-120",style:(_vm.buttonStyle),attrs:{"id":"desktop-menu","tag":_vm.onSearchPage ? 'div' : 'button',"slim":"","dark":""},on:{"click":() => !_vm.onSearchPage && _vm.toggleMenu(true)},scopedSlots:_vm._u([{key:"default",fn:function(){return [(!_vm.onSearchPage)?_c('span',[_vm._v("Menu")]):_c('span',[_vm._v("Luk")])]},proxy:true},{key:"icon",fn:function(){return [(!_vm.onSearchPage)?_c('SvgIconMenu'):_c('SvgIconClose')]},proxy:true}],null,false,4051953229)}):_vm._e()],1)],1)],1)])],1)]),_vm._v(" "),_c('div',{staticClass:"c-site-header__bottom-bar >=768:hidden isolate",class:[
			'fixed bottom-0 left-0 w-full px-layout-margin pb-md',
			'flex justify-end items-center gap-sm',
			{
				'c-site-header__bottom-bar--has-gradient':
					(!_vm.isSticky || _vm.searchHasFocus || _vm.searchHasInput) &&
					_vm.isFrontpage,
			},
		]},[_c('Transition',{attrs:{"name":"t-site-header__mobile-search","mode":"out-in"}},[(_vm.searchPage)?_c('SiteHeaderSearch',{key:`state-${_vm.onSearchPage}`,ref:"mobileSearch",staticClass:"flex-shrink flex-grow-0",class:{
					'w-full':
						(!_vm.isSticky || _vm.searchHasFocus || _vm.searchHasInput) &&
						_vm.isFrontpage,
					'duration-500 ease-smooth-out': !_vm.onSearchPage,
					'fixed top-md left-layout-margin w-5/5col direction-reverse':
						_vm.onSearchPage,
				},style:(_vm.onSearchPage ? _vm.contentStyle : ''),attrs:{"show-input":((!_vm.isSticky || _vm.searchHasFocus || _vm.searchHasInput) &&
						_vm.isFrontpage) ||
					_vm.onSearchPage,"button-mode":_vm.onSearchPage ? 'button' : 'link',"white-background":""},nativeOn:{"focusin":function($event){return _vm.onSearchFocus.apply(null, arguments)},"focusout":function($event){return _vm.onSearchBlur.apply(null, arguments)}}}):_vm._e()],1),_vm._v(" "),(_vm.navigation && !_vm.onSearchPage)?_c('BaseButton',{staticClass:"w-48 h-48 flex-shrink-0",attrs:{"id":"mobile-menu","dark":"","aria-label":"Åben menu"},on:{"click":() => _vm.toggleMenu(true)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIconMenu')]},proxy:true}],null,false,2095407193)}):(_vm.onSearchPage)?_c('NuxtLinkExt',{attrs:{"to":_vm.previousPath}},[_c('BaseButton',{staticClass:"w-48 h-48 flex-shrink-0",attrs:{"id":"mobile-menu","dark":"","aria-label":"Luk søgning"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIconClose')]},proxy:true}])})],1):_vm._e()],1),_vm._v(" "),_c('div',{class:[
			'fixed top-0 left-0 w-full h-full pointer-events-none',
			'bg-black bg-opacity-75 opacity-0',
			'duration-1000 ease-smooth-out',

			{
				'opacity-100': _vm.menuIsOpen,
			},
		]}),_vm._v(" "),_c('Portal',{attrs:{"name":"BurgerMenu","to":"overlay"}},[_c('BurgerMenu',_vm._b({key:"BurgerMenu",on:{"close":() => _vm.toggleMenu(false)}},'BurgerMenu',{ menuIsOpen: _vm.menuIsOpen },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }