<template>
	<nav class="c-skip-to-content-custom">
		<div
			class="inline-block py-11 border-2 border-black bg-white rounded-12"
			style="min-width: 12em"
		>
			<h2
				class="
					text-picker
					font-darker-grotesque font-bold
					pl-14
					pr-20
					mb-xs
				"
			>
				Spring til...
			</h2>
			<ul>
				<template v-for="({ id, name, listeners }, index) in skipItems">
					<li v-show="existingIds.includes(id)" :key="index">
						<a
							class="
								text-picker
								font-darker-grotesque font-semibold
								opacity-70
								flex
								items-center
								gap-x-32
								pl-14
								pr-20
								focus:bg-background focus:opacity-100
								hover:bg-background hover:opacity-100
							"
							:href="`#${id}`"
							v-on="listeners"
							v-text="name"
						></a>
					</li>
				</template>
			</ul>
		</div>
	</nav>
</template>

<script>
import SkipToContent from '~/citi-baseline/components/SkipToContent';

export default {
	name: 'SkipToContentCustom',
	extends: SkipToContent,
};
</script>

<style>
:where(.c-skip-to-content-custom) {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 0;
	overflow: hidden;
}

:where(.c-skip-to-content-custom:focus-within) {
	height: auto;
	overflow: visible;
}
</style>
