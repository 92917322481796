<template>
	<div v-if="breakingActive && !closed" class="c-breaking-banner">
		<!-- Close button -->
		<div
			class="
				absolute
				flex
				justify-end
				>=1024:justify-start
				right-layout-margin
				>=1024:right-0
				w-1/5col
				>=1024:w-1/12col
				pointer-events-none
			"
		>
			<BaseButton
				dark
				:class="[
					'w-34 h-34',
					'>=1024:w-40 >=1024:h-40',
					'pointer-events-auto',
				]"
				@click="closeBreakingNews"
			>
				<template #icon>
					<SvgClose class="w-full" />
				</template>
			</BaseButton>
		</div>

		<!-- Content -->
		<div class="c-breaking-banner__content >=1024:w-7/12col">
			<h2
				v-if="breakingTitle"
				class="
					font-darker-grotesque font-bold
					text-title-breaking-banner
				"
				v-html="breakingTitle"
			></h2>
			<div
				v-if="breakingDescription"
				class="s-rich-text s-rich-text--monotone"
				:class="breakingTitle && 'mt-sm'"
				v-html="breakingDescription"
			></div>
			<BaseButton
				v-if="breakingLink && breakingLink.url"
				tag="NuxtLinkExt"
				class="inline-block"
				:class="(breakingTitle || breakingDescription) && 'mt-md'"
				dark
				:to="breakingLink.url"
				:target="breakingLink.target"
			>
				<span
					class="whitespace-nowrap pr-4"
					v-text="breakingLink.name"
				></span>
			</BaseButton>
		</div>
	</div>
</template>

<script>
import SvgClose from '~/assets/svgs/icon-close.svg?inline';

export default {
	name: 'BreakingBanner',

	components: {
		SvgClose,
	},

	props: {
		breakingActive: {
			type: Boolean,
			default: false,
		},
		breakingHash: {
			type: String,
			default: '',
		},
		breakingTitle: {
			type: String,
			default: '',
		},
		breakingDescription: {
			type: String,
			default: '',
		},
		breakingLink: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			closed: true,
		};
	},
	mounted() {
		if (
			localStorage &&
			localStorage.breakingNewsClose &&
			// eslint-disable-next-line eqeqeq
			localStorage.breakingNewsClose == this.breakingHash
		) {
			this.closed = true;
		} else {
			// Open the banner, but only if there's actually any content
			this.closed = !(
				this.breakingTitle ||
				this.breakingDescription ||
				(this.breakingLink && this.breakingLink.url)
			);
		}
	},
	methods: {
		closeBreakingNews() {
			localStorage.breakingNewsClose = this.breakingHash;
			this.closed = true;
		},
	},
};
</script>

<style lang="postcss">
.c-breaking-banner {
	@apply relative bg-breaking px-layout-margin py-32 z-10;

	/* How much horizontal space to take up */
	width: calc(100% + 2 * var(--theme-layout-margin, 16px));
	margin-inline: calc(var(--theme-layout-margin, 16px) * -1);
	@apply >=768:w-full >=768:mx-0;
}

.c-breaking-banner__content {
	& :first-child {
		@apply max-w-4/5col >=1024:max-w-full;
	}
}
</style>
