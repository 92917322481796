<template>
	<div class="c-site-footer">
		<div class="relative h-80 >=1024:h-180 overflow-hidden">
			<!-- Lens Flare Desktop -->
			<LensFlare
				id="c-site-footer__flare-desktop"
				:class="[
					'absolute top-1/2 left-1/2 w-visual-screen max-w-layout-max',
					'transform -translate-x-1/2 -translate-y-1/2',
					'hidden >=1024:block',
				]"
				:angle="8"
				:min-blur-amount="5"
				:max-blur-amount="30"
				:flares="[
					{
						size: 625,
						offset: 0,
						targetOffset: -150,
						theme: 'primary',
						opacity: 1,
					},
					{
						size: 255,
						targetSize: 30,
						offset: -300,
						targetOffset: -740,
						theme: 'secondary',
						opacity: 0.8,
					},
					{
						size: 235,
						targetSize: 180,
						offset: 230,
						targetOffset: 570,
						theme: 'secondary',
						opacity: 0.5,
					},
					{
						size: 135,
						targetSize: 0,
						offset: 230,
						targetOffset: 1230,
						theme: 'secondary',
						opacity: 0.5,
					},
				]"
			/>

			<!-- Lens Flare Mobile -->
			<LensFlare
				id="c-site-footer__flare-mobile"
				:class="[
					'absolute top-1/2 left-1/2 w-visual-screen ',
					'transform -translate-x-1/2 -translate-y-1/2',
					'>=1024:hidden',
				]"
				:angle="15"
				:min-blur-amount="15"
				:max-blur-amount="25"
				:flares="[
					{
						size: 625,
						offset: -50,
						targetOffset: 50,
						theme: 'primary',
						opacity: 1,
					},
					{
						size: 375,
						targetSize: 150,
						offset: -300,
						targetOffset: -450,
						theme: 'secondary',
						opacity: 0.8,
					},
					{
						size: 285,
						targetSize: 150,
						offset: 250,
						targetOffset: 650,
						theme: 'secondary',
						opacity: 0.5,
					},
				]"
			/>
		</div>

		<footer
			id="footer"
			:class="[
				'max-w-layout-max px-layout-margin mx-auto',
				'flex flex-col items-start >=1024:grid >=1024:grid-cols-12',
				'>=1024:gap-x-layout-gutter >=1024:gap-y-lg',
				'pb-6xl pt-4xl',
			]"
		>
			<!-- First Column -->
			<SvgLogo
				class="row-start-1 col-start-1 col-end-7 h-57 >=1024:h-72"
			/>
			<h2 class="sr-only">Odsherred Kommune</h2>

			<div
				v-if="address"
				:class="[
					'row-start-2 row-end-4 col-start-1 col-end-7',
					'mt-2xl >=1024:mt-0',
				]"
			>
				<div>
					{{ address.street }}<br />
					{{ address.postalCode }} {{ address.city }}
				</div>

				<div v-if="address.phone || address.email" class="mt-lg">
					<a
						v-if="address.phone"
						class="block"
						:href="`tel:${address.phone}`"
					>
						{{ address.phone }}
					</a>
					<a
						v-if="address.email"
						class="block"
						:href="`mailto:${address.email}`"
					>
						{{ address.email }}
					</a>
				</div>
			</div>

			<div
				v-if="address && address.cvr"
				class="row-start-4 col-start-1 col-end-7 mt-lg"
			>
				CVR: {{ address.cvr }}
			</div>

			<!-- Second Column -->
			<template v-if="contact">
				<div
					v-if="contact.title && contact.title.length"
					:class="[
						'row-start-1 col-start-7 col-end-10',
						'mt-3xl mb-lg >=1024:my-0',
					]"
				>
					<BaseH3 tag="h2" v-text="contact.title" />
				</div>

				<div class="row-start-2 col-start-7 col-end-10">
					<ul v-if="contact.openingHours">
						<li
							v-for="(item, index) in computedOpeningHours"
							:key="`opening-hour-${index}`"
							class="openinghours flex items-end gap-x-10"
							v-html="formatOpeningHour(item)"
						></li>
					</ul>
				</div>

				<div
					v-if="contact.book && contact.book.url"
					:class="[
						'row-start-3 col-start-7 col-end-10',
						'mt-lg >=1024:mt-0',
					]"
				>
					<NuxtLinkExt
						:to="contact.book.url"
						:target="contact.book.target"
						:class="[
							'c-site-footer__link pr-8',
							'flex items-center space-x-16',
							'text-button font-medium font-darker-grotesque',
							'group',
						]"
					>
						<SvgArrow
							:class="[
								'w-16 text-text transform',
								{
									'-rotate-45':
										contact.book.target === '_blank' ||
										isExternal(contact.book.url),
								},
							]"
						/>

						<div
							:class="[
								'mb-4',
								'duration-500 ease-smooth-out',
								'transform group-hover:translate-x-8',
							]"
							v-text="
								contact.book.name !== contact.book.url
									? contact.book.name
									: 'Bestil tid'
							"
						></div>
					</NuxtLinkExt>
				</div>
			</template>

			<!-- Third Column -->
			<template v-if="seeAlso">
				<div
					v-if="seeAlso.title && seeAlso.title.length"
					class="
						row-start-1
						col-start-10 col-end-13
						mt-3xl
						>=1024:mt-0
					"
				>
					<BaseH3 tag="h2" v-text="seeAlso.title" />
				</div>

				<div
					:class="[
						'row-start-2 col-start-10 col-end-13',
						'mt-lg >=1024:mt-0',
					]"
				>
					<div class="space-y-8">
						<NuxtLinkExt
							v-for="(link, index) in seeAlso.links"
							:key="`see-also-link-${index}`"
							:to="link.url"
							:target="link.target"
							:class="[
								'c-site-footer__link pr-8',
								'flex items-start space-x-16',
								'text-button font-medium font-darker-grotesque',
								'group',
							]"
						>
							<SvgArrow
								:class="[
									'flex-shrink-0',
									'mt-4 w-16 text-text',

									{
										'transform -rotate-45':
											link.url === '_blank' ||
											isExternal(link.url),
									},
								]"
							/>

							<div
								:class="[
									'mb-4',
									'duration-500 ease-smooth-out',
									'transform group-hover:translate-x-8',
								]"
								v-text="link.name"
							></div>
						</NuxtLinkExt>
					</div>
				</div>

				<div
					v-if="seeAlso.readOutLoud || !isSystemReducedMotion"
					:class="[
						'row-start-3 col-start-10 col-end-13',
						'space-y-8',
						'mt-8 >=1024:mt-0',
					]"
				>
					<NuxtLinkExt
						v-if="seeAlso.readOutLoud"
						:to="seeAlso.readOutLoud.url"
						:target="seeAlso.readOutLoud.target"
						:class="[
							'flex items-center space-x-12',
							'text-button font-medium font-darker-grotesque',
							'group pr-8',
						]"
					>
						<SvgSpeaker class="flex-shrink-0 w-18" />
						<div
							:class="[
								'mb-4',
								'duration-500 ease-smooth-out',
								'transform group-hover:translate-x-8',
							]"
							v-text="'Læs højt'"
						></div>
					</NuxtLinkExt>
					<button
						v-if="!isSystemReducedMotion"
						:class="[
							'flex items-center space-x-12',
							'text-button font-medium font-darker-grotesque',
							'group pr-8',
						]"
						@click="toggleAnimation"
					>
						<SvgIconPlay
							v-if="isReducedMotion"
							class="flex-shrink-0 w-18"
						/>
						<SvgIconPause v-else class="flex-shrink-0 w-18" />
						<div
							:class="[
								'mb-4',
								'duration-500 ease-smooth-out',
								'transform group-hover:translate-x-8',
							]"
							v-text="
								isReducedMotion
									? 'Slå animeret pynt til'
									: 'Slå animeret pynt fra'
							"
						></div>
					</button>
				</div>
			</template>

			<div v-if="someItems" class="row-start-4 col-start-10 col-end-13">
				<div class="flex space-x-12 mt-4xl >=1024:mt-lg">
					<a
						v-for="({ serviceName, url, icon }, index) in someItems"
						:key="`some-${index}`"
						:href="url"
						:aria-label="`gå til ${serviceName}`"
					>
						<Component :is="icon" class="text-text w-32" />
					</a>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import SvgLogo from '~/assets/svgs/logo.svg?inline';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgIconPlay from '~/assets/svgs/icon-play.svg?inline';
import SvgIconPause from '~/assets/svgs/icon-pause.svg?inline';
import SvgFacebook from '~/assets/svgs/icon-facebook.svg?inline';
import SvgInstagram from '~/assets/svgs/icon-instagram.svg?inline';
import SvgLinkedIn from '~/assets/svgs/icon-linkedin.svg?inline';
import SvgTwitter from '~/assets/svgs/icon-twitter.svg?inline';
import SvgSpeaker from '~/assets/svgs/icon-speaker.svg?inline';
import LensFlare from '~/components/shared/LensFlare';

const iconMap = {
	facebookUrl: 'SvgFacebook',
	instagramUrl: 'SvgInstagram',
	linkedInUrl: 'SvgLinkedIn',
	twitterUrl: 'SvgTwitter',
};

export default {
	name: 'SiteFooter',

	components: {
		SvgLogo,
		SvgArrow,
		SvgIconPlay,
		SvgIconPause,
		SvgFacebook,
		SvgInstagram,
		SvgLinkedIn,
		SvgTwitter,
		SvgSpeaker,
		LensFlare,
	},

	data() {
		return {
			isSystemReducedMotion: true,
		};
	},

	computed: {
		...mapState({
			isReducedMotion: (state) => state.isReducedMotion,
			address: (state) => state.site?.footer?.address,
			contact: (state) => state.site?.footer?.contact,
			seeAlso: (state) => state.site?.footer?.seeAlso,
			someItems: (state) =>
				Object.keys(state.site?.soMeItems || {})
					.map((type) => ({
						serviceName: type.split('Url')[0],
						url: state.site?.soMeItems[type],
						icon: iconMap[type],
					}))
					.filter((e) => Boolean(e.url)),
		}),

		computedOpeningHours() {
			const { openingHours } = this.contact?.openingHours;
			return openingHours?.reduce((acc, cur) => {
				const { dayOfTheWeek, hoursOfBusiness, isOpen } = cur;
				const opensAt = hoursOfBusiness[0]?.opensAt;
				const closesAt = hoursOfBusiness[0]?.closesAt;

				if (
					isOpen &&
					acc.length &&
					!acc[acc.length - 1].ended &&
					acc[acc.length - 1].startTime === opensAt &&
					acc[acc.length - 1].endTime === closesAt
				) {
					acc[acc.length - 1].endDay = dayOfTheWeek;
					return acc;
				}

				if (isOpen) {
					acc.push({
						startDay: dayOfTheWeek,
						startTime: opensAt,
						endTime: closesAt,
					});

					return acc;
				}

				acc[acc.length - 1].ended = true;
				return acc;
			}, []);
		},
	},

	mounted() {
		this.isSystemReducedMotion =
			window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
			window.matchMedia(`(prefers-reduced-motion: reduce)`).matches ===
				true;
	},

	methods: {
		formatOpeningHour(item) {
			const { startDay, startTime, endTime, endDay } = item;
			const startTimeDate = new Date(startTime);
			const endTimeDate = new Date(endTime);

			const startTimeHours = `${startTimeDate.getHours()}`.slice(-2);
			const startTimeMinutes = `0${startTimeDate.getMinutes()}`.slice(-2);
			const endTimeHours = `${endTimeDate.getHours()}`.slice(-2);
			const endTimeMinutes = `0${endTimeDate.getMinutes()}`.slice(-2);

			const startTimeString = `${startTimeHours}.${startTimeMinutes}`;
			const endTimeString = `${endTimeHours}.${endTimeMinutes}`;

			const days = startDay + (endDay ? `-${endDay}` : '') + ':';
			const hours = `${startTimeString}-${endTimeString}`;
			return `<span>${days}</span><span>kl. ${hours}</span>`;
		},

		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}

				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},

		toggleAnimation() {
			if (this.isReducedMotion) {
				location.hash = '#fjern-reduceret-bevaegelse';
				location.reload();
			} else {
				location.hash = '#reduceret-bevaegelse';
				location.reload();
			}
		},
	},
};
</script>

<style lang="postcss">
/* Part of the page transitioning */
.c-site-footer {
	@apply transition duration-300 ease-in-out;
}
.js-page-transition:not(.js-page-transition--enter) .c-site-footer {
	@apply transform translate-y-6 opacity-0;
}

.c-site-footer__link {
	word-break: break-word;
}

.c-site-footer .openinghours {
	text-transform: lowercase;
}

.c-site-footer .openinghours span:first-child:first-letter {
	text-transform: capitalize;
}
</style>
