var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"c-site-header-logo",class:{
		'c-site-header-logo--collapsed': _vm.collapse,
	}},[_c(_vm.to ? 'NuxtLinkExt' : 'span',{tag:"Component",staticClass:"relative inline-flex items-baseline w-full min-w-fit h-full",style:({
			paddingLeft: `${Math.round((62 / 163) * 10000) / 100}%`,
		}),attrs:{"to":_vm.to,"tabindex":_vm.tabindex,"data-focus":".c-site-header-logo__coat-of-arms"}},[_vm._l((2),function(index){return [_c('SvgCoatOfArms',{key:index,staticClass:"c-site-header-logo__coat-of-arms absolute left-0 transition-all duration-500",style:({
					top: '-45%',
					width: `${Math.round((46 / 163) * 10000) / 100}%`,
					...(_vm.collapse
						? {
								transform: 'translateY(-12%)',
						  }
						: {}),
				})})]}),_vm._v(" "),_c('span',{staticClass:"inline-block font-darker-grotesque font-bold text-logo uppercase transition-all duration-500"},[_vm._v("\n\t\t\tOdsherred\n\t\t\t"),_c('br'),_vm._v(" "),_c('span',{staticClass:"absolute transition-opacity duration-500",class:_vm.collapse && 'opacity-0 select-none pointer-events-none',attrs:{"aria-hidden":_vm.collapse ? 'true' : null}},[_vm._v("\n\t\t\t\tKommune\n\t\t\t")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }