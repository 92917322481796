<template>
	<div v-if="isOpen" class="c-auto-complete-list-custom">
		<div class="px-lg">
			<hr class="w-full h-2 text-background" />
			<div v-if="isLoading" class="text-search-subtitle py-xs">
				Søger efter forslag...
			</div>
			<div
				:id="`${id}__title`"
				class="text-search-subtitle py-xs"
				:class="isLoading && 'sr-only'"
			>
				Søgeforslag
			</div>
		</div>
		<ul
			v-show="!isLoading"
			:id="id"
			role="listbox"
			:aria-labelledby="`${id}__title`"
		>
			<li
				v-for="(result, i) in suggestions"
				:id="getId(i)"
				:key="i"
				role="option"
				class="
					flex
					items-center
					font-semibold
					text-search-suggestion
					px-lg
					py-xs
					cursor-pointer
				"
				:class="{
					'bg-background text-text': isSelected(i),
					'>=768:-mt-4 -mt-8': i,
					'-mb-xs': i === suggestions.length - 1,
				}"
				:aria-selected="isSelected(i)"
				@click="setResult(result.text)"
			>
				<SvgIconSearch class="flex-shrink-0 w-xs h-xs mr-xs" />
				{{ result.text.charAt(0).toUpperCase() + result.text.slice(1) }}
			</li>
		</ul>
	</div>
</template>

<script>
import AutoCompleteList from '~/citi-baseline/components/AutoCompleteList';
import SvgIconSearch from '~/assets/svgs/icon-search.svg?inline';

export default {
	name: 'AutoCompleteListCustom',

	components: {
		SvgIconSearch,
	},

	// The component types extend upon the shared logic of the baseline component.
	// Learn about extends: https://v2.vuejs.org/v2/api/#extends
	extends: AutoCompleteList,
};
</script>

<style lang="postcss">
.c-auto-complete-list-custom {
	@apply font-darker-grotesque font-medium bg-white text-text-placeholder border-2 border-current rounded-b-24 >=768:rounded-b-30 pb-lg;
	border-width: 1.5px;
	@apply border-t-0;
}
</style>
