var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Transition',{attrs:{"name":"t-burger-menu","duration":"1000","appear":""}},[(_vm.menuIsOpen)?_c('div',{staticClass:"c-burger-menu relative bg-white h-full overflow-hidden"},[_c('ScopedColorTheme',{staticClass:"h-full",attrs:{"theme":_vm.activeTheme}},[(_vm.menuIsOpen)?_c('LensFlare',{class:[
					'c-burger-menu__lens-flare',
					'absolute bottom-0 right-0',
					'<1024:top-0 <1024:bottom-auto',
					'w-1/2',
				],attrs:{"id":"c-burger-menu__flare","angle":_vm.flareAngle,"min-blur-amount":15,"max-blur-amount":15,"flares":[
					{
						size: 1000,
						offset: 300,
						opacity: 1,
						theme: 'primary',
						classes: [
							'c-burger-menu__flare-first',
							'duration-1000 ease-smooth-out',
						],
					},
					{
						size: 500,
						offset: -200,
						opacity: 0.8,
						theme: 'secondary',
						classes: [
							'c-burger-menu__flare-second',
							'duration-1000 ease-smooth-out',
						],
					},
				]}}):_vm._e(),_vm._v(" "),_c('div',{class:[
					'relative max-w-layout-max',
					'mx-auto h-full overflow-y-scroll',
					'px-layout-margin pt-xl pb-md >=1024:pt-lg >=1024:pb-4xl',
					'flex flex-col justify-between',
				]},[_c('div',[_c('div',{class:[
							'hidden >=1024:flex',
							'justify-between items-center',
						]},[_c('BaseButton',{ref:"closeButtonDesktop",staticClass:"order-2",attrs:{"dark":"","tabindex":"1"},on:{"click":_vm.closeOverlay},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s('LUK')}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgClose',{staticClass:"w-14"})]},proxy:true}],null,false,1169231079)}),_vm._v(" "),_c('BaseH4',{staticClass:"mb-3",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s('I Odsherred kan du')}})],1),_vm._v(" "),_c('div',{class:[
							'relative',
							'flex >=1024:grid >=1024:grid-cols-12',
							'gap-x-layout-gutter >=1024:mt-3xl',
							'duration-500 ease-smooth-out',
						],style:({
							height: _vm.contentHeight
								? `${_vm.contentHeight}px`
								: 'auto',
						})},[_c('TransitionExt',{attrs:{"name":"burger-menu-parent","duration":500},on:{"enter":_vm.updateContentHeight}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.activeItem),expression:"!activeItem"}],ref:"parentItems",class:[
									'w-5/5col absolute top-0 left-0',
									'>=1024:block >=1024:relative >=1024:w-auto',
									'>=1024:col-start-1 >=1024:col-end-6',
								]},[_c('BaseH4',{staticClass:"mb-3xl >=1024:hidden",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s('I Odsherred kan du')}}),_vm._v(" "),_c('ul',{staticClass:"space-y-lg"},_vm._l((_vm.main),function(item,index){return _c('li',{key:`main-item-${index}`},[_c('button',{class:[
												'flex items-center',
												'text-menu-category font-darker-grotesque',
												'duration-500 ease-smooth-out',
												'transform group text-left',

												{
													'opacity-60 hover:opacity-100':
														_vm.activeItem &&
														_vm.activeItem.id !==
															item.id,
												},
											],attrs:{"aria-controls":`menu-children-${item.id}`,"aria-expanded":String(
													!!(
														_vm.activeItem &&
														_vm.activeItem.id ===
															item.id
													)
												),"tabindex":_vm.getItemTabindex(item)},on:{"click":() => _vm.setActiveItem(item)}},[_c('BurgerMenuArrow',{staticClass:"flex-shrink-0",attrs:{"active":_vm.activeItem &&
													_vm.activeItem.id ===
														item.id}}),_vm._v(" "),_c('span',{class:[
													'mb-6 transform',
													'transition-transform duration-500 ease-smooth-out',

													{
														'translate-x-20':
															_vm.activeItem &&
															_vm.activeItem.id ===
																item.id,
														'group-hover:translate-x-20':
															!_vm.activeItem ||
															_vm.activeItem.id !==
																item.id,
													},
												],domProps:{"innerHTML":_vm._s(item.title)}})],1)])}),0)],1)]),_vm._v(" "),_c('div',{class:[
								'w-5/5col',
								'>=1024:relative >=1024:w-auto',
								'>=1024:col-start-7 >=1024:col-end-11',
							]},[_c('TransitionExt',{attrs:{"name":"burger-menu-children","duration":500},on:{"enter":_vm.updateContentHeight}},[(_vm.activeItem)?_c('div',{key:`children-${_vm.activeItem.id}`,ref:"childrenItems",class:[
										'w-full absolute top-0 left-0',
										'duration-500 ease-smooth-out',
										'>=1024:pt-sm',
									],attrs:{"id":`menu-children-${_vm.activeItem.id}`}},[_c('button',{class:[
											'flex items-center gap-x-sm',
											'>=1024:hidden mb-2xl',
										],attrs:{"tabindex":"1"},on:{"click":() => _vm.setActiveItem(null)}},[_c('SvgCaret',{staticClass:"w-12 transform rotate-90"}),_vm._v(" "),_c('div',{class:[
												'text-h4 mb-3',
												'font-darker-grotesque font-bold',
											],domProps:{"textContent":_vm._s('Tilbage')}})],1),_vm._v(" "),_c('h2',{class:[
											'>=1024:hidden mb-2xl',
											'text-menu-category font-medium',
											'font-darker-grotesque',
										],domProps:{"innerHTML":_vm._s(_vm.activeItem.title)}}),_vm._v(" "),_c('ul',{staticClass:"space-y-md"},_vm._l((_vm.activeItem.children),function(child,index){return _c('li',{key:`child-${index}`,class:[
												'flex gap-x-8 items-center',
												'text-h3 font-darker-grotesque',
												'duration-500 ease-smooth-out',

												{
													'transform hover:translate-x-8':
														child.id !== _vm.pageId,
												},
											]},[(child.id === _vm.pageId)?_c('SvgCaret',{class:[
													'flex-shrink-0 text-primary w-12',
													'duration-500 ease-smooth-out',
													'transform -rotate-90',
												]}):_vm._e(),_vm._v(" "),_c('NuxtLinkExt',{staticClass:"mb-3",attrs:{"to":child.url,"target":child.target,"tabindex":"1"},domProps:{"innerHTML":_vm._s(child.title)}})],1)}),0)]):_vm._e()])],1)],1)]),_vm._v(" "),_c('div',{class:[
						'c-burger-menu__bottom',
						'grid grid-cols-1 gap-sm max-w-fit mt-2xl',
						'>=1024:flex >=1024:flex-wrap >=1024:w-5/12col',
					]},[_vm._l((_vm.computedSecondary),function(item,index){return _c('NuxtLinkExt',{key:`secondary-${index}`,attrs:{"to":item.url,"target":item.target,"tabindex":"2"}},[_c('BaseButton',{staticClass:"<1024:h-48 <1024:min-w-full",attrs:{"dark":!item.isExternal,"light":item.isExternal},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"mb-1 >=1024:mb-0",domProps:{"innerHTML":_vm._s(item.title || item.name)}})]},proxy:true},{key:"icon",fn:function(){return [(item.isExternal)?_c('SvgArrow',{staticClass:"transform -rotate-45"}):_vm._e()]},proxy:true}],null,true)})],1)}),_vm._v(" "),_c('BaseButton',{ref:"closeButtonMobile",class:[
							'c-burger-menu__close-mobile w-48 h-48 >=1024:hidden',
							'fixed bottom-md right-layout-margin',
						],attrs:{"dark":"","aria-label":"Luk menu","tabindex":"2"},on:{"click":_vm.closeOverlay},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgClose',{staticClass:"w-14"})]},proxy:true}],null,false,2741470254)})],2)])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }